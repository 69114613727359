import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import IconAdd from '../../components/Icons/IconAdd';
import IconLess from '../../components/Icons/IconLess';
import IconCircleRemove from '../../components/Icons/IconCircleRemove';
import { functionUtils } from '@utils/function';
import { FileSize } from '@utils/enums/FileSize';
import LazyImage from '@components/LazyImage';
import { Link } from 'gatsby';
import { cartService } from '@services/cartService';
import constant from '@utils/constant';
import PopConfirm from '@components/PopConfirm';
import _orderBy from 'lodash/orderBy';
import _join from 'lodash/join';
import { ProductStatus } from '@utils/enums/ProductStatus';
import { storeDispatcher } from '@store/dispatcher';
import Currency from '@components/Currency';

const CartItem = memo((props) => {
   const {
      uid,
      item_id,
      item_type,
      slug,
      attachments,
      name,
      price,
      local_price,
      options,
      quantity,
      quantity_left,
      status,
      total,
      local_total,
      loading,
      isLocalCart,
   } = props;

   const [isActiveDeleteConfirmation, setIsActiveDeleteConfirmation] = useState(false);
   const [quantityNo, setQuantityNo] = useState(quantity || 1);
   const timerClick = useRef();

   const isOutOfStock = useMemo(() => {
      return status !== ProductStatus.AVAILABLE || quantity_left === 0;
   }, [status, quantity_left]);

   useEffect(() => {
      return () => {
         if (timerClick.current) {
            clearTimeout(timerClick.current);
         }
      };
   }, []);

   useEffect(() => {
      setQuantityNo(quantity);
   }, [quantity]);

   useEffect(() => {
      if (quantity_left > 0 && quantity_left < quantity && !isOutOfStock) {
         const reUpdateQuantity = async (quantity) => {
            const item = {
               item_id,
               item_type,
               quantity,
            };
            if (!isLocalCart) {
               await cartService.updateCartItem(uid, item);
            } else {
               storeDispatcher.updateToLocalCart(item);
            }
         };
         reUpdateQuantity(quantity_left);
      }
   }, [quantity, quantity_left, uid, item_id, item_type, isOutOfStock, isLocalCart]);

   const upDownQuantity = (isDecrease) => () => {
      const newQuantity = isDecrease ? quantityNo - 1 : quantityNo + 1;
      clearTimeout(timerClick.current);
      setQuantityNo(newQuantity);
      timerClick.current = setTimeout(() => {
         changeQuantityValue(newQuantity);
      }, 300);
   };

   const onChange = (e) => {
      const value = e.target.value;
      if (`${value}`.trim() !== '') {
         setQuantityNo(Number(value) < 1 ? 1 : value);
      } else {
         setQuantityNo('');
      }
   };

   const onBlur = () => {
      let newQuantity = Number(quantityNo);
      if (!`${quantityNo}`.trim()) {
         newQuantity = quantity;
      } else if (newQuantity > quantity_left) {
         newQuantity = quantity_left;
      } else if (newQuantity < 1) {
         newQuantity = 1;
      }
      setQuantityNo(newQuantity);
      if (newQuantity !== quantity) {
         changeQuantityValue(newQuantity);
      }
   };

   // update quantity
   const changeQuantityValue = useCallback(
      async (newQuantity) => {
         if (!loading && newQuantity !== quantity && newQuantity >= 1) {
            const item = {
               item_id,
               item_type,
               quantity: newQuantity,
            };
            if (!isLocalCart) {
               await cartService.updateCartItem(uid, item);
            } else {
               storeDispatcher.updateToLocalCart(item);
            }
         }
      },
      [quantity, item_id, item_type, uid, loading, isLocalCart]
   );

   // remove
   const removeProduct = useCallback(async () => {
      if (!isLocalCart) {
         await cartService.deleteCartItem({
            uids: [uid],
         });
      } else {
         storeDispatcher.removeFromLocalCart(item_id);
      }
   }, [uid, isLocalCart, item_id]);

   // current product option
   const renderProductOption = useCallback(() => {
      if (options?.length) {
         let optionsDataList = options.map((option) => {
            return {
               typeCode: option.type?.code,
               name: option.name,
               uid: option.uid,
            };
         });
         optionsDataList = _orderBy(optionsDataList, ['typeCode'], ['desc']);
         const orderedOptionsNameArr = optionsDataList.map((o) => o.name);
         return (
            <div className="current-option">{_join(orderedOptionsNameArr, ' / ')}</div>
         );
      }
      return null;
   }, [options]);

   const imageSrc = useMemo(() => {
      if (attachments?.length) {
         const imgItem = attachments[0]?.formats?.[FileSize.THUMBNAIL];
         return imgItem || '';
      }
      return '';
   }, [attachments]);

   const isShowQuantityWarning = useMemo(() => {
      if (
         quantity_left <= constant.maxProductNumberToShowWarningInCart ||
         +(quantity_left - quantityNo) <= constant.maxProductNumberToShowWarningInCart
      ) {
         return true;
      }
      return false;
   }, [quantity_left, quantityNo]);

   const linkToProduct = `/${slug}`;

   return (
      <div
         className={`cart-item ${
            isOutOfStock ? 'out-of-stock' : ''
         } animate__animated animate__fadeIn`}
      >
         <Row gutter={30}>
            <Col lg={15} md={24} sm={24} xs={24}>
               <div className="arc">
                  <Link to={linkToProduct}>
                     <LazyImage className="cart-item__img" src={imageSrc} title={name} />
                  </Link>
                  <div className="cart-item__info">
                     <Link to={linkToProduct} className="name">
                        {name}
                     </Link>
                     {isShowQuantityWarning && (
                        <span className="warning">
                           {quantity_left} item{quantity_left > 1 ? 's' : ''} left
                        </span>
                     )}
                     <span className="price">
                        <Currency price={price} localPrice={local_price} />
                     </span>
                     <div className="sub-info">
                        {renderProductOption()}
                        <PopConfirm
                           zIndex={4039}
                           title="Are you sure to remove this product?"
                           placement="bottomLeft"
                           isOpen={isActiveDeleteConfirmation}
                           onVisibleChange={setIsActiveDeleteConfirmation}
                           onConfirm={removeProduct}
                        >
                           <button
                              className={`remove ${
                                 isActiveDeleteConfirmation ? 'active' : ''
                              }`}
                           >
                              <IconCircleRemove />
                              Remove
                           </button>
                        </PopConfirm>
                     </div>
                  </div>
               </div>
            </Col>
            <Col lg={9} md={24} sm={24} xs={24}>
               <div className="arc cart-item__total">
                  <div className="cart-item__change-number">
                     <button
                        className="decrease"
                        onClick={upDownQuantity(true)}
                        disabled={quantityNo <= 1 || isOutOfStock}
                     >
                        <IconLess />
                     </button>
                     <input
                        type="number"
                        value={quantityNo}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={isOutOfStock}
                     />
                     <button
                        className="increase"
                        onClick={upDownQuantity()}
                        disabled={quantityNo >= quantity_left || isOutOfStock}
                     >
                        <IconAdd />
                     </button>
                  </div>
                  <span className="total">
                     <Currency price={total} localPrice={local_total} />
                  </span>
               </div>
            </Col>
         </Row>
      </div>
   );
});

export default CartItem;
