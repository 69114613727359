import React, { memo, useState } from 'react';
import { Collapse } from 'reactstrap';
import { eventService } from '@services/eventService';
import { message } from '@components/Message';

const Discount = memo(({ codeList = [], onApplied }) => {
   const [isOpen, setIsOpen] = useState(false);
   const [code, setCode] = useState('');
   const [submitting, setSubmitting] = useState(false);

   const onApply = async () => {
      if (codeList.some(c => c === code.trim())) {
         message.error('This coupon code has already been applied');
         return;
      }
      if (code.trim()) {
         await setSubmitting(true);
         const newCodes = [...(codeList || []).map(c => c), code.trim()];
         const response = await eventService.checkCoupon({
            codes: newCodes,
         });
         if (response.isSuccess) {
            onApplied(newCodes, response.data);
            setIsOpen(false);
            setCode('');
         } else {
            if (response.message.includes('does not exist')) {
               message.error('Invalid coupon code');
            } else {
               message.error(response.message);
            }
         }

         setSubmitting(false);
      }
   };

   return (
      <div className={'discount-item'}>
         <span
            className={`add-promo ${isOpen ? 'active' : ''}`}
            onClick={() => setIsOpen((v) => !v)}
         >
            Add a coupon code
         </span>
         <Collapse isOpen={isOpen}>
            <div className={`enter-promo-code ${isOpen ? 'open' : ''}`}>
               <input
                  placeholder="Enter a coupon code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  onKeyDown={e => e.keyCode === 13 && onApply()}
               />
               <button onClick={onApply} disabled={submitting}>
                  Apply
               </button>
            </div>
         </Collapse>
      </div>
   );
});

export default Discount;
