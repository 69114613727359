import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import IconArrow2 from '../../components/Icons/IconArrow2';
import SEO from '../../components/Common/SEO';
import CartItem from '../../views/cart/CartItem';
import { navigate } from 'gatsby';
import constant from '@utils/constant';
import Footer from '../../views/default-layout/footer/Footer';
import Discount from '../../views/cart/Discount';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import {
   getCartSelector,
   getLocalCartSelector,
   getProfileSelector,
} from '@utils/selectors';
import ContentLoading from '@views_admin/ContentLoading';
import { CurrencyUnit } from '@utils/enums/CurrencyUnit';
import { message } from '@components/Message';
import { cartService } from '@services/cartService';
import { storeDispatcher } from '@store/dispatcher';
import Currency from '@components/Currency';

const Cart = memo(() => {
   const { data: userProfile } = useSelector(getProfileSelector);

   const { cart, items, loading, error } = useSelector(getCartSelector);
   const localCartItems = useSelector(getLocalCartSelector);

   const [cartInfo, setCartInfo] = useState({
      cart: null,
      items: [],
   });
   const [isLoading, setIsLoading] = useState(loading ?? true);
   const [codes, setCodes] = useState([]);
   const [discountInfo, setDiscountInfo] = useState({
      discount: 0,
      localDiscount: 0,
   });

   const { summary = 0, local_summary = 0, total = 0, local_total = 0, fee = 0 } =
      cartInfo.cart || {};

   const isLocalCart = useMemo(() => {
      return userProfile && !loading ? false : true;
   }, [userProfile, loading]);

   useEffect(() => {
      if (localStorage[constant.COUPONS_VARIABLE]) {
         localStorage.removeItem(constant.COUPONS_VARIABLE);
      }
      return () => {
         storeDispatcher.clearCartError();
      };
   }, []);

   useEffect(() => {
      if (error?.message && !isLocalCart) {
         message.error(error.message);
      }
   }, [error, isLocalCart]);

   useEffect(() => {
      if (loading) {
         setIsLoading(loading);
      } else {
         console.log('isLocalCart', isLocalCart);
         if (!isLocalCart) {
            setCartInfo({
               cart: cart,
               items: items,
            });
            setIsLoading(false);
         } else {
            // load local cart
            const loadLocalCartInfo = async (localCartItems) => {
               await setIsLoading(true);
               const response = await cartService.getLocalCartDetails({
                  items: localCartItems,
               });
               console.log(response);
               if (response.isSuccess && response.data?.cart) {
                  setCartInfo({
                     cart: response.data.cart,
                     items: response.data.items || [],
                  });
               }
               setIsLoading(false);
            };

            const cartInfo = {
               cart: null,
               items: [],
            };
            if (localCartItems?.length) {
               loadLocalCartInfo(localCartItems);
            } else {
               setCartInfo(cartInfo);
               setIsLoading(false);
            }
         }
      }
   }, [isLocalCart, cart, items, localCartItems, loading]);

   const checkOut = () => {
      if (codes.length) {
         localStorage[constant.COUPONS_VARIABLE] = JSON.stringify({
            codes,
            discountInfo,
         });
      }
      setTimeout(() => {
         navigate(constant.ROUTE_CHECKOUT);
      }, 100);
   };

   const applyCode = useCallback((newCodes, discountInfo) => {
      if (!newCodes) {
         return;
      }
      setCodes(newCodes);
      setDiscountInfo({
         discount: discountInfo.discount,
         localDiscount: discountInfo.local_discount,
      });
   }, []);

   const hasCoupon = useMemo(() => {
      return codes.length > 0;
   }, [codes]);

   const { checkoutTotal, checkoutLocalTotal } = useMemo(() => {
      const checkoutTotal = total - (discountInfo.discount || 0);
      const checkoutLocalTotal = local_total - (discountInfo.localDiscount || 0);
      return {
         checkoutTotal: checkoutTotal > 0 ? checkoutTotal : 0,
         checkoutLocalTotal: checkoutLocalTotal > 0 ? checkoutLocalTotal : 0,
      };
   }, [total, local_total, discountInfo]);

   return (
      <>
         <SEO title={'Cart'} />
         <ContentLoading
            className="cart-page-loading"
            isLoading={isLoading && cartInfo.items.length === 0}
         />
         <Row gutter={30} justify="center">
            <Col xl={16} lg={24} md={24} sm={24} xs={24}>
               <div className="cart-page">
                  {cartInfo.items.length === 0 && !isLoading && (
                     <div className="empty">
                        <label>Your cart is empty.</label>
                        <Link to={constant.ROUTE_PRODUCTS}>Continue shopping.</Link>
                     </div>
                  )}
                  {cartInfo.items.length !== 0 && (
                     <div className="cart-content">
                        <div className="title">
                           <span>SHOPPING CART</span>
                           <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                              <IconArrow2 />
                           </span>
                        </div>
                        <ProductList
                           items={cartInfo.items}
                           loading={isLoading}
                           isLocalCart={isLocalCart}
                        />
                        <div className="price-row">
                           <span>Subtotal</span>
                           <span>
                              <Currency price={summary} localPrice={local_summary} />
                           </span>
                        </div>

                        <div className="price-row">
                           <div
                              className={`cart-discount-group ${
                                 hasCoupon ? 'has-discount' : ''
                              }`}
                           >
                              {hasCoupon && 'Coupons applied'}
                              <div className="coupons-applied">
                                 {codes.map((code) => (
                                    <span className="coupons-applied__item" key={code}>
                                       {code}
                                    </span>
                                 ))}
                              </div>
                              <span className="discount">
                                 <Discount codeList={codes} onApplied={applyCode} />
                              </span>
                           </div>
                           {hasCoupon && (
                              <span>
                                 -{' '}
                                 {
                                    <Currency
                                       price={discountInfo.discount}
                                       localPrice={discountInfo.localDiscount}
                                    />
                                 }
                              </span>
                           )}
                        </div>
                        <div className="price-row total">
                           <span>Total</span>
                           <span>
                              <Currency
                                 price={checkoutTotal}
                                 localPrice={checkoutLocalTotal}
                              />
                           </span>
                        </div>
                        <button
                           className="checkout-btn"
                           onClick={checkOut}
                           disabled={isLocalCart}
                        >
                           {isLocalCart ? 'PLEASE SIGN IN TO CHECK OUT' : 'CHECK OUT'}
                        </button>
                     </div>
                  )}
               </div>
            </Col>
         </Row>
         <Footer />
      </>
   );
});

const ProductList = memo(({ items, isLoading, isLocalCart }) => {
   return (
      <div className="cart-list">
         {items &&
            items.map((item) => {
               return (
                  <CartItem
                     key={item.uid ? item.uid : item.item_id}
                     {...item}
                     loading={isLoading}
                     isLocalCart={isLocalCart}
                  />
               );
            })}
      </div>
   );
});

export default Cart;
